<template>
  <div>
    <Navigation />
    <!-- <ElButton @click="populate">
      sync search
    </ElButton> -->
    <div class="flex pa-5 space-between">
      <!-- <ElButton @click="populate">test </ElButton> -->
      <ProjectFilters />
      <ElButton
        v-if="filters.stage !== 'completed'"
        @click="createProject"
        type="primary"
        >Adauga
        <span v-if="filters.stage === 'proposal'">Oferta</span>
        <span v-else>Lucrare</span>
      </ElButton>
    </div>
    <!-- table -->
    <ProjectsTable
      :search="filters"
      :query="query"
      :filter="filter"
      :row-class-name="getRowClassName"
    >
      <ElTableColumn prop="data.name" width="400" label="Nume">
        <template v-if="row.data" slot-scope="{ row }">
          <span class="mr-2">
            <span class="font-bold">#{{ row.data.number }} </span>
            -
            <span v-if="row.data.createdAt">{{
              row.data.createdAt.toDate() | date
            }}</span>
          </span>
          {{ row.data.name }}
          <el-button
            @click="$set(filters, 'groupid', row.data.groupid)"
            v-if="row.data.hasgroup"
            class="font-bold"
            type="primary"
            size="small"
            plain
            round
          >
            G: {{ row.data.groupid }}
          </el-button>
          <!-- hotfix  nu am timp sa ma uit cum e corect un check de undefined pe fiecare field! -->
          <span v-if="!filters.name">
            <el-alert
              show-icon
              v-if="
                row.data &&
                  hasStockProblem(row.data) &&
                  filters.stage !== 'completed'
              "
              title="Stoc insuficient"
              type="error"
            ></el-alert>
          </span>
          <span v-else>
            <el-alert
              show-icon
              title="La cautare nu se calculeaza stoc"
              type="error"
            ></el-alert>
          </span>

          <!-- {{ materialsById }} -->
        </template>
      </ElTableColumn>

      <ElTableColumn label="Client" width="150">
        <ResourceLoader
          v-if="row.data"
          slot-scope="{ row }"
          :path="`clients/${row.data.clientId}`"
        >
          <ElButton
            slot-scope="{ resource: client }"
            @click="$refs.clientDialog.edit(client.id)"
            size="mini"
            icon="el-icon-user"
          >
            <span v-if="client && client.data">
              <span>{{ truncateClient(client.data.name) }}</span>
            </span>
            <span v-else>
              -
            </span>
          </ElButton>
        </ResourceLoader>
      </ElTableColumn>

      <ElTableColumn
        v-if="filters.stage !== 'completed'"
        align="left"
        label="Prioritate"
        width="85"
      >
        <ElSwitch
          active-color="#13ce66"
          slot-scope="{ row }"
          v-if="row.data"
          :value="row.data.priority"
          @input="togglePriority(row, $event)"
        />
      </ElTableColumn>

      <ElTableColumn align="left" label="Stadiu" width="150">
        <ElSwitch
          slot-scope="{ row }"
          :value="row.data.active"
          @input="toggleActive(row, $event)"
        />
        <ElSelect
          size="mini"
          slot-scope="{ row }"
          v-if="row.data"
          :value="row.data.stage"
          @change="changeStage(row, $event)"
        >
          <ElOption label="Oferta" value="proposal" />
          <ElOption label="Lucrare" value="pending" />
          <ElOption label="In Lucru" value="active" />
          <ElOption label="Finalizat" value="completed" />
        </ElSelect>
      </ElTableColumn>

      <ElTableColumn label="Modifica" width="150">
        <template slot-scope="{ row }">
          <ElDropdown
            split-button
            type="primary"
            @click="$router.push(`/projects/${row.id}`)"
            size="mini"
          >
            Modifica
            <ElDropdownMenu slot="dropdown">
              <ElDropdownItem
                @click.native="$router.push(`/activities?project=${row.id}`)"
                >Activitati</ElDropdownItem
              >
              <ElDropdownItem @click.native="duplicateProject(row)"
                >Duplicare</ElDropdownItem
              >
              <ElDropdownItem @click.native="openDeletePrompt(row)"
                >Sterge</ElDropdownItem
              >
              <ElDropdownItem @click.native="printQuote(row.id)" v-if="row.data"
                >Tiparire Oferta</ElDropdownItem
              >
              <ElDropdownItem @click.native="printInfo(row.id)"
                >Tiparire Fisa</ElDropdownItem
              >
            </ElDropdownMenu>
          </ElDropdown>
        </template>
      </ElTableColumn>

      <ElTableColumn label="FSC" width="50">
        <template slot-scope="{ row }">
          <ElTag v-if="row.data.fsc" size="mini" type="success">FSC </ElTag>
          <ElTag v-else size="mini" type="success">
            -
          </ElTag>
        </template>
      </ElTableColumn>

      <div>
        <!-- activities?project=FDKTj0uvHkrRIen3tNWm -->
        <ElTableColumn
          v-if="filters.stage !== 'completed'"
          label="Progres"
          width="auto"
          prop="data.dueDate"
        >
          <template slot-scope="{ row }">
            <div v-if="row && row.data">
              <el-date-picker
                v-model="row.data.dueDate"
                type="datetime"
                size="small"
                format="dd MMM yyyy"
                value-format="timestamp"
                disabled
                :clearable="false"
              >
              </el-date-picker>
              {{ row.data.dueDate | daysLeft }}
            </div>
            <div v-if="row && row.data" class="flex">
              <ElProgress
                class="flex-1 mt-1 mr-3"
                status="success"
                :percentage="getProgress(row)"
                :text-inside="true"
                :stroke-width="18"
              />
              <el-button
                @click="openTreeDialog(row.id)"
                icon="el-icon-share"
                circle
              ></el-button>
              <el-button
                @click="navigateTo('activities?project=' + row.id)"
                icon="el-icon-search"
                circle
              ></el-button>
            </div>
            <ul>
              <li v-for="(taskName, index) in getReadyTasks(row)" :key="index">
                {{ taskName }}
              </li>
            </ul>
          </template>
        </ElTableColumn>

        <ElTableColumn v-else label="Financiar" width="400">
          <template slot-scope="{ row }">
            <div>
              <div class="flex row">
                <span class="font-bold" v-if="row.data.finalQuantity">
                  Fabricate:
                  {{ parseFloat(row.data.finalQuantity) | number }}
                </span>
                <span v-else>
                  ?
                </span>

                <div class="ml-2">
                  <el-popover
                    title="Descriere"
                    width="200"
                    trigger="hover"
                    :content="row.data.description"
                  >
                    <el-button
                      size="small"
                      icon="el-icon-info"
                      slot="reference"
                    ></el-button>
                  </el-popover>
                </div>
              </div>
              <div class="ml-2" v-if="row.data.resultText">
                ({{ row.data.resultText }})
              </div>
              <div class="font-bold" v-if="row.data.variables.quantity">
                Tiraj:
                {{ parseFloat(row.data.variables.quantity) | number }}
              </div>
              <div v-else>
                ?
              </div>

              <span v-if="!row.data.fixPrice">
                <span v-if="unitPrice(row.data)">
                  x ({{ unitPrice(row.data) | number }} Ron / buc) =
                </span>
                <span v-else>
                  ?
                </span>
              </span>
              <span v-else>
                <span v-if="row.data.fixPrice">
                  x ({{ row.data.fixPrice | number }} Ron / buc
                  <span class="font-bold">( pret fix)</span>
                  ) =
                </span>
                <span v-else>
                  ?
                </span>
              </span>
              <span v-if="!row.data.fixPrice" class="boldText">
                {{
                  (row.data.variables.quantity * unitPrice(row.data)) | number
                }}
              </span>
              <span v-else>
                {{
                  (parseFloat(row.data.fixPrice) * row.data.variables.quantity)
                    | number
                }}
              </span>
              <span v-if="row.data && row.data.priceWithTva" class="boldText">
                (tva inclus)
              </span>
              <span v-else class="boldText">
                (fara tva)
              </span>
            </div>
            <div v-if="row.data.payed">
              Achitat: {{ row.data.payed | number }}
              <span v-if="row.data.fixPrice">
                Rest :
                {{
                  (parseFloat(row.data.fixPrice) * row.data.variables.quantity -
                    parseFloat(row.data.payed))
                    | number
                }}
              </span>
              <span class="font-bold" v-else>
                Rest :
                {{
                  (unitPrice(row.data) * row.data.variables.quantity -
                    parseFloat(row.data.payed))
                    | number
                }}
              </span>
            </div>
          </template>
        </ElTableColumn>

        <ElTableColumn
          v-if="filters.stage === 'completed'"
          align="left"
          :label="filters.stage === 'completed' ? 'Livrat' : ''"
          width="180"
        >
          <template slot-scope="{ row }">
            <ElSwitch
              active-color="#13ce66"
              :value="row.data.delivered"
              @input="setDeliveryDetails(row, $event)"
            />
            <div v-if="row.data.deliveryDetails">
              <div class="font-bold">
                {{ row.data.deliveryDetails.delegate }}
              </div>
              <div v-if="row.data.deliveryDetails.invoiceDocId">
                F: {{ row.data.deliveryDetails.invoiceDocId }}
              </div>
              <div v-if="row.data.deliveryDetails.transportDocId">
                Av: {{ row.data.deliveryDetails.transportDocId }}
              </div>
              <div v-if="row.data.deliveryDetails.deliveredAt">
                <TimeAgo
                  type="showDate"
                  v-if="row.data.deliveryDetails.deliveredAt"
                  :date="row.data.deliveryDetails.deliveredAt.toDate()"
                />
              </div>
            </div>
          </template>
        </ElTableColumn>
      </div>
    </ProjectsTable>
    <!-- load more -->
    <div v-if="!filters.name" class="px-5">
      <ElButton icon="el-icon-more" @click="loadMore">Incarca mai multe</ElButton>
    </div>
    <div v-else class="flex px-5 row">
      <ElButton icon="el-icon-d-arrow-left" @click="nextSearchPage(-1)"
        >anterior</ElButton
      >
      <div class="pt-2 mx-2">
        <span class="font-bold">
          {{ searchPage + 1 }}
        </span>
        / {{ nbPages }} ( {{ nbHits }} lucrari gasite )
      </div>
      <ElButton icon="el-icon-d-arrow-right" @click="nextSearchPage(1)"
        >urmatoare</ElButton
      >
    </div>

    <el-dialog width="60%" top="0" :visible.sync="projectPreviewDialog">
      <div v-if="projectPreviewId">
        <project-schema-preview
          ref="preview"
          :id="projectPreviewId"
        ></project-schema-preview>
      </div>
    </el-dialog>
    <!-- delivery details -->
    <el-dialog
      width="60%"
      top="0"
      title="Completeaza"
      :visible.sync="deliveryDetailsDialog"
    >
      <div v-if="deliveryDetails">
        <el-input placeholder="nume delegat" v-model="deliveryDetails.delegate">
        </el-input>
        <el-input
          placeholder="nr factura"
          v-model="deliveryDetails.invoiceDocId"
        ></el-input>
        <el-input
          placeholder="nr aviz"
          v-model="deliveryDetails.transportDocId"
        ></el-input>
        <el-button
          type="primary"
          class="mt-5"
          :disabled="
            deliveryDetails.delegate === '' ||
              (deliveryDetails.invoiceDocId === '' &&
                deliveryDetails.transportDocId === '')
          "
          @click="toggleDelivery(selectedRow, true)"
          >salveaza</el-button
        >
      </div>
    </el-dialog>
    <ClientDialog ref="clientDialog" />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import FiltersMixin from "@/mixins/FiltersMixin";
import LoadMoreMixin from "@/mixins/LoadMoreMixin";
import ProjectFilters from "@/components/projects/ProjectFilters";
import ProjectSchemaPreview from "./ProjectSchemaPreview";
import { getProjectGraph } from "@/utils/graph";
import ClientDialog from "@/components/clients/ClientDialog";
import keyBy from "lodash/keyBy";
import { getMaterialQuantityTotals } from "@/calculator";
// For the default version
const algoliasearch = require("algoliasearch");
const client = algoliasearch("N75IYJ6ESL", "68fc35166e35a5e2cb78881d23103f1e");
const index = client.initIndex("aska");


export default {
  mixins: [FiltersMixin(), LoadMoreMixin({ limit: 10 })],
  components: {
    ProjectFilters,
    ClientDialog,
    ProjectSchemaPreview,
  },
  computed: {
    materialsById() {
      return keyBy(this.materials, "id");
    },
    searchName() {
      return this.filters.name;
    },
    query() {
      let query = this.$firestore()
        .collection("projects")
        .limit(this.limit)
        .orderBy("createdAt", "desc");
      
        
      if ((this.filters.name || this.filters.number || this.filters.groupid) && this.searchQuery) {
        query = this.searchQuery;
      }
      if(!this.filters.noFilters) {

      
      if (this.filters.stage) {
        query = query.where("stage", "==", this.filters.stage);
      }

      if (this.filters.client) {
        query = query.where("clientId", "==", this.filters.client);
      }

      if (this.filters.fsc) {
        query = query.where("fsc", "==", true);
      }
    }

      return query;
    },
  },
  methods: {
    setDeliveryDetails(row, $event) {
      //TODO  cum pot sa modific direct pe row
      if (row.deliveryDetails || !$event) {
        this.toggleDelivery(row, $event);
      } else {
        this.selectedRow = cloneDeep(row);
        if (row.data.deliveryDetails) {
          this.deliveryDetails = cloneDeep(row.data.deliveryDetails);
        } else {
          this.deliveryDetails = {
            delegate: "",
            transportDocId: "",
            invoiceDocId: "",
          };
        }
        this.deliveryDetailsDialog = true;
      }
    },
    nextSearchPage(page) {
      if (this.searchPage === 0 && page === -1) {
        page = 0;
      }
      this.searchPage += page;
      this.searchInAngolia(this.filters);
    },
    truncateClient(text) {
      return (text ? text.slice(0, 10) : "-") + (text.length >= 10 ? ".." : "");
    },
    async populate() {
      let query = await this.$firestore()
        .collection("projects")
        .get()
        .then((querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            objectID: doc.id,
            name: doc.data().name,
            clientId: doc.data().clientId,
            stage: doc.data().stage,
            number: doc.data().number,
            groupid: doc.data().groupid
              ? parseInt(doc.data().groupid)
              : parseInt(doc.data().number),
            fsc: doc.data().fsc,
            delivered: doc.data().delivered,
          }));
          // let index = documents.map(x => {
          //   'name': x.name,
          //   'objectID':
          // })\
          index.replaceAllObjects(documents).then(({ objectIDs }) => {
            // console.log(objectIDs);
          });
          index
            .setSettings({
              searchableAttributes: ["name", "number", "groupid"],
            })
            .then(() => {
              // done
              alert("done");
            });
          // console.log(documents);
          // do something with documents
        });
      // index.replaceAllObjects(query)

      // console.log(client)
    },
    hasStockProblem(project) {
      if (project) {
        let hasProblem = false;
        this.quantityTotalsAsRows(project).forEach((material) => {
          if (
            this.materialsById[material.id] &&
            this.materialsById[material.id].data
          ) {
            if (
              material.quantity >
              this.materialsById[material.id].data.currentQuantity //necesar // stoc existent
            ) {
              hasProblem = true;
            }
          }
        });
        return hasProblem;
      }
      return false;
    },
    quantityTotalsAsRows(project) {
      return Object.entries(this.quantityTotals(project)).map(
        ([id, quantity]) => ({
          id,
          quantity,
        })
      );
    },
    quantityTotals(project) {
      return getMaterialQuantityTotals(
        Object.values(project.tasks),
        project.variables
      );
    },
    async openTreeDialog(id) {
      const isRendered = this.projectPreviewId;
      this.projectPreviewId = id;
      if (isRendered) {
        await this.$refs.preview.fetchProject(id);
        await this.$refs.preview.refresh();
      }
      this.projectPreviewDialog = true;
    },
    unitPrice(project) {
      return project.price / project.variables.quantity;
    },
    navigateTo(link) {
      this.$router.push({ path: link });
    },
    price(price) {
      if (price) {
        return parseFloat(price).toFixed(2);
      }
      return 0;
    },
    filter(row) {
      const { filters } = this;
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false;
        }
      }
      if (filters.notdelivered && row.data.delivered) {
        return false;
      }
      return true;
    },
    togglePriority(project, priority) {
      this.$firestore()
        .doc(`projects/${project.id}`)
        .update({
          priority,
          updatedAt: this.$firestore.FieldValue.serverTimestamp(),
        });
      this.$store.addActivity(
        `Lucrarea a fost ${priority ? "prioritizata" : "deprioritizata"}`,
        project.id
      );
    },
    toggleDelivery(project, delivered) {
      if (this.deliveryDetails) {
        this.deliveryDetails.deliveredAt = this.$firestore.FieldValue.serverTimestamp();
      }
      this.$firestore()
        .doc(`projects/${project.id}`)
        .update({
          delivered,
          deliveryDetails: this.deliveryDetails ? this.deliveryDetails : false,
          updatedAt: this.$firestore.FieldValue.serverTimestamp(),
        });

      this.deliveryDetailsDialog = false;
      // this.$store.addActivity(
      //   `Lucrarea a fost ${delivered ? 'livrata' : 'ne-livrata'}`,
      //   project.id
      // )
    },
    changeStage(project, stage) {
      this.$firestore()
        .doc(`projects/${project.id}`)
        .update({
          stage,
          updatedAt: this.$firestore.FieldValue.serverTimestamp(),
        });
      this.$store.addActivity(`Lucrarea a fost actualizata`, project.id);
    },
    getReadyTasks(project) {
      return getProjectGraph(project.data)
        .nodes("[type='task'][!completed]")
        .filter((node) => node.incomers("[type='task']").allAre("[?completed]"))
        .map(
          (node) =>
            `${node.data().name} ${
              node.isChild() ? node.parent().data().name : null
            }`
        );
    },
    getProgress(project) {
      const tasks = project.data.tasks ? Object.values(project.data.tasks) : [];
      if (tasks.length === 0) return 0;
      const completed = tasks.filter((task) => task.completed);
      return Math.round((completed.length / tasks.length) * 100);
    },
    async createProject() {
      if (this.filters.stage == undefined) {
        alert("selecteaza un tab (oferte, comenzi,in lucru)");
        return;
      }
      const number = await this.getNextProjectNumber();
      const newProj = {
        name: "",
        number,
        groupid: number,
        hasgroup: false,
        stage: this.filters.stage,
        active: false,
        priority: false,
        edges: {},
        tasks: {},
        dueDate: null,
        components: {},
        variables: {
          quantity: 1000,
          width: 148,
          height: 210,
          surplus_rise: 1.01,
          surplus_base: 50,
        },
        margin: 20,
        price: null,
        fixPrice: null,
        imageUrl: null,
        createdAt: this.$firestore.FieldValue.serverTimestamp(),
        updatedAt: this.$firestore.FieldValue.serverTimestamp(),
      };
      const projectRef = await this.$firestore()
        .collection("projects")
        .add(newProj);
      this.$router.push("/projects/" + projectRef.id);
    },
    async duplicateProject(row) {
      const number = await this.getNextProjectNumber();
      let {
        name,
        edges,
        tasks,
        components,
        variables,
        imageUrl,
        description,
        margin,
        price,
        stage,
        clientId,
        fsc,
        fixPrice,
      } = row.data;
      stage = "pending";
      Object.keys(tasks).forEach((taskId) => {
        tasks[taskId].completed = false;
        tasks[taskId].active = false;
        tasks[taskId].result = 0;
        tasks[taskId].materialsDebited = null;
        tasks[taskId].debitedQuantities = null;
        tasks[taskId].employeeIds = [];
      });

      try {
        let objToDupliate = {
          name: name + "-duplicat",
          number,
          active: false,
          priority: false,
          edges,
          tasks,
          stage,
          components,
          variables,
          margin,
          price,
          createdAt: this.$firestore.FieldValue.serverTimestamp(),
          updatedAt: this.$firestore.FieldValue.serverTimestamp(),
        };

        if (fsc) {
          objToDupliate.fsc = fsc;
        }
        if (description) {
          objToDupliate.description = description;
        }
        if (clientId) {
          objToDupliate.clientId = clientId;
        }
        if (fixPrice) {
          objToDupliate.fixPrice = fixPrice;
        }
        const docRef = await this.$firestore()
          .collection("projects")
          .add(objToDupliate);

        if (imageUrl) {
          const gsReference = this.$storage().refFromURL(imageUrl);
          gsReference.getMetadata().then((metadata) => {
            gsReference.getDownloadURL().then((url) => {
              fetch(url)
                .then((res) => res.blob())
                .then(async (myBlob) => {
                  const myFile = new File([myBlob], metadata.name, {
                    type: metadata.contentType,
                  });
                  const path = `projects/${docRef.id}/${metadata.name}`;
                  const resp = await this.$store.uploadFile(myFile, path);
                  await this.$firestore()
                    .collection("projects")
                    .doc(docRef.id)
                    .update({
                      imageUrl: resp,
                    });
                });
            });
          });
        }

        this.$message({
          message: "Duplicare facuta cu succes!",
          type: "success",
          duration: "1000",
        });
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      }
      this.$router.replace("/projects?stage=pending");
    },
    openDeletePrompt(row) {
      this.$confirm(
        "Esti pe cale sa stergi lucrarea " +
          row.data.name +
          ", Doresti sa continui ?",
        "Atentie",
        {
          confirmButtonClass: "confirmDelete",
          confirmButtonText: "Sterge",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deleteProject(row);
        })
        .catch(() => {});
    },
    async deleteProject(row) {
      this.$firestore()
        .collection("projects")
        .doc(row.id)
        .delete();
    },
    getNextProjectNumber() {
      const settingsRef = this.$firestore()
        .collection("settings")
        .doc("main");

      return this.$firestore().runTransaction(async (transaction) => {
        const settingsDoc = await transaction.get(settingsRef);
        const nextProjectNumber = settingsDoc.data().nextProjectNumber;
        transaction.update(settingsRef, {
          nextProjectNumber: this.$firestore.FieldValue.increment(1),
        });
        return nextProjectNumber;
      });
    },
    getRowClassName({ row: project }) {
      if (project.data && project.data.price === null) {
        return "bg-red-200";
      }
    },
    windowOpen(...args) {
      const win = window.open(...args);
      if (!win) this.$alert("Browser-ul a blocat deschiderea ferestrei.");
    },
    printQuote(id) {
      const path = `/print/project-quote/${id}`;
      this.windowOpen(this.$router.resolve(path).href);
    },
    printInfo(id) {
      const path = `/print/project-info/${id}`;
      this.windowOpen(this.$router.resolve(path).href);
    },
    async searchInAngolia(nw) {
      let res = null;
      if (nw.name) {
        if (nw.client) {
          res = await index.search(nw.name, {
            filters: "clientId:" + nw.client,
            page: this.searchPage,
          });
        } else {
          res = await index.search(nw.name, {
            facets: ["name"],
            page: this.searchPage,
          });
        }
        let searchList = res.hits;

        this.nbHits = res.nbHits;
        this.nbPages = res.nbPages;

        searchList = searchList.map((x) => x.objectID);
        this.searchResult = searchList;

        if (searchList && searchList.length !== 0) {
          this.searchQuery = this.$firestore()
            .collection("projects")
            .where(this.$firestore.FieldPath.documentId(), "in", searchList);
        } else {
          this.searchQuery = null;
        }
      } else {
        this.searchQuery = null;
      }
    },
    async searchByNumber(nw) {
      this.searchQuery = this.$firestore()
        .collection("projects")
        .where("number", "==", parseInt(nw.number))
    },
    async searchByGroup(nw) {
      this.searchQuery = this.$firestore()
        .collection("projects")
        .where("groupid", "==", parseInt(nw.groupid))
    },
  },
  created() {
    // this.setFilter()
    this.$bind("materials", this.$firestore().collection("materials"));
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      async handler(filters) {
        if (filters.name) {
          this.searchPage = 0;
          this.searchInAngolia(filters);
        }
        if (filters.number) {
          this.searchPage = 0;
          this.searchByNumber(filters);
        }
        if (filters.groupid) {
          this.searchByGroup(filters);
        }
      },
    },
  },
  data() {
    return {
      searchQuery: null,
      deliveryDetails: null,
      selectedRow: null,
      deliveryDetailsDialog: false,
      nbPages: 0,
      nbHits: 0,
      searchPage: 0,
      searchResult: null,
      materials: [],
      projectPreviewId: null,
      projectPreviewDialog: false,
    };
  },
};
</script>

<style>
.boldText {
  font-weight: bold;
}
.confirmDelete {
  background: red !important;
}
</style>
